import { Container, Row, Col } from "react-bootstrap";
import { colors } from "../../utilities/constants";

interface IHeroPagesProps {
  title: string;
  background?: string;
}

export const HeroPages = ({
  title,
  background = "/assets/images/hero-about.png",
}: IHeroPagesProps) => {
  const headerPages: React.CSSProperties = {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    minHeight: "300px",
    color: colors.light,
  };

  return (
    <section
      style={headerPages}
      className="d-flex flex-column justify-content-center"
    >
      <Container>
        <Row>
          <Col className="text-center">
            <h1 className="m-0 text-uppercase fw-bold">{title}</h1>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
