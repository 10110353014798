import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import NumberFormat from 'react-number-format';
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "./styleGallery.css";
import { HeroProject } from "../../components/generic";
import { Project } from "../../interfaces/projects.interface";
import api from "../../services/api";

const MySwal = withReactContent(Swal)

export const ProjectPage = () => {

  let { id } = useParams();
  const [project, setProject] = useState({} as Project);
  const [isLoadingProjects, setIsLoadingProjects] = useState(true)

  const loadProjects = async () => {
    const response = await api.projects.getProjectById()
    try {
      if (response.ok) {
        const data: Project[] = await response.json()
        const projectLoad: any = data.find(p => p.id === id)
        setProject(projectLoad)
      } else {
        MySwal.fire({
          title: 'Error!',
          text: `${response.statusText}. Please reload page.`,
          icon: 'error',
          confirmButtonText: 'Close'
        })
      }
    } catch (error: any) {
        MySwal.fire({
          title: 'Error!',
          text: error,
          icon: 'error',
          confirmButtonText: 'Close'
        })
    } finally {
      setIsLoadingProjects(false)
    }
  }

  useEffect(() => {
    loadProjects()
  }, [id])

  if (isLoadingProjects) {
    return <div>Loading...</div>
  }

  return (
    <>
      <HeroProject image={project.image} />
      <main className='my-5'>
        <Container>
          <Row>
            <Col xs={12} md={8}>
              <h1>{project.name}</h1>
              <div dangerouslySetInnerHTML={{__html:project.description.replace(/\n/g, '<br /><br />')}}></div>
            </Col>
            <Col xs={12} md={4}>
              <div className="gridDetailsProject">
                <h5 className="mb-4">{project.comment}</h5>
                <p>{project.comment2}</p>
                <p><NumberFormat value={project.amount} displayType={'text'} thousandSeparator={true} prefix={'$'} /></p>
              </div>
            </Col>
          </Row>
        </Container>

        {project.gallery.length > 0 &&
          <section className='gridGalleryProject mt-5'>
            <Container>
              <Row>
                <Col>
                  <h2 className="mb-5 h2Before">Gallery</h2>
                </Col>
              </Row>
              <Row>
                <Swiper
                  loop={true}
                  spaceBetween={10}
                  navigation={true}
                  autoHeight={true}
                  pagination={{type: 'bullets', dynamicBullets: true}}
                  modules={[FreeMode, Navigation, Pagination]}
                  className="mySwiper2"
                >
                  {
                    project.gallery.map((src: string, index) => (
                      <SwiperSlide key={index+src}>
                        <img src={src} alt={src.split('/').pop()} />
                      </SwiperSlide>
                    ))
                  }
                </Swiper>
              </Row>
            </Container>
          </section>
        }
      </main>
    </>
  )
}