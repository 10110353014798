import { Container, Row, Col } from "react-bootstrap";
import clases from "./generic.module.css";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCards } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-cards";
import './sliders.css';

export const GallerySlider = () => {
  const images = [
    "/assets/images/project-1.webp",
    "/assets/images/project-2.webp",
    "/assets/images/project-3.webp",
    "/assets/images/project-4.webp",
  ];

  return (
    <section className='my-5' id="gallerySlider">
      <Container>
        <Row>
          <Col className='text-center'>
            <h2 className={`${clases.h2After} ${clases.marginAfterAuto} mt-5`}>
              Recents Projects
            </h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <Swiper
              className='swiperProjects'
              effect={"cards"}
              grabCursor={true}
              modules={[Pagination, EffectCards]}
              pagination={{ clickable: true, dynamicBullets: true }}
            >
              {images.map((image) => (
                <SwiperSlide key={image}>
                  <img src={image} alt={image} />
                </SwiperSlide>
              ))}
            </Swiper>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
