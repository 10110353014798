import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { colors } from "../../utilities/constants";
import clases from "./generic.module.css";
import "swiper/css";
import "swiper/css/pagination";
import "./sliders.css";

interface ITestimonialProps {
  id: string;
  comment: string;
  client: string;
}

export const Testimonials = () => {
  const testimonials: ITestimonialProps[] = [
    {
      id: "1",
      comment:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget consectetur consectetur, nisi nisl consectetur nisi, euismod nisl nisi euismod nisl.",
      client: "John Doe",
    },
    {
      id: "2",
      comment:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget consectetur consectetur, nisi nisl consectetur nisi, euismod nisl nisi euismod nisl.",
      client: "John Doe",
    },
    {
      id: "3",
      comment:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget consectetur consectetur, nisi nisl consectetur nisi, euismod nisl nisi euismod nisl.",
      client: "John Doe",
    },
  ];

  return (
    <section className="d-flex align-items-center mb-5">
      <Container>
        <Row>
          <Col md={6}>
            <h2 className={clases.h2BeforePrimary}>
              What do our
              <br />
              customers say?
            </h2>
          </Col>
          <Col md={6}>
            <Swiper
              grabCursor={true}
              modules={[Pagination]}
              spaceBetween={10}
              slidesPerView={1}
              pagination={{ clickable: true, dynamicBullets: true }}
            >
              {testimonials.map(({ id, comment, client }) => (
                <SwiperSlide key={id}>
                  <figure className="d-flex flex-column align-items-start">
                    <FontAwesomeIcon icon={faQuoteLeft} size="6x" />
                    <blockquote>{comment}</blockquote>
                    <footer>
                      — <b style={{ color: colors.primary }}>{client}</b>
                    </footer>
                  </figure>
                </SwiperSlide>
              ))}
            </Swiper>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
