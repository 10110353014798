export default {
  categories: {
    getCategories: () =>
      fetch("data/categories.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        }
      })
  },
  projects: {
    getProjects: () =>
      fetch("data/projects.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        }
      }),
    getProjectById: () =>
      fetch("../data/projects.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        }
      })
  },
  team: {
    getTeamMembers: () => 
      fetch("../data/team.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        }
      })
  }
};
