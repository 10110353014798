import { Col, Container, Row } from "react-bootstrap";
import { motion } from "framer-motion";
import { colors } from "../../utilities/constants";
import styles from "./generic.module.css";
import { fadeInContainerWithStagger, fadeInUp } from "../../utilities/fadeInUp";
import { useRef, useState, useEffect } from "react";

export const FeaturesBanner = () => {
  const outerRef = useRef(null);
  const [inViewport, setInViewport] = useState(true);

  const features = [
    {
      label: "general",
      labelFeatured: "contractor",
      icon: "assets/images/img-contractor.webp",
      id: 1,
    },
    {
      label: "construction",
      labelFeatured: "management",
      icon: "assets/images/img-management.webp",
      id: 2,
    },
    {
      label: "design",
      labelFeatured: "build",
      icon: "assets/images/img-build.webp",
      id: 3,
    },
    {
      label: "hotel",
      labelFeatured: "renovations",
      icon: "assets/images/img-millwright.webp",
      id: 4,
    },
  ];

  useEffect(() => {
    const onChange = (entries: any) => {
      entries.forEach((entry: any) => {
        if (entry.target === outerRef.current) {
          if (entry.isIntersecting) {
            setInViewport(true);
          }
        }
      });
    };
    const observer = new IntersectionObserver(onChange, { threshold: 0.5 });
    observer.observe(outerRef.current!);
  }, [outerRef]);

  return (
    <Container>
      <Row ref={outerRef}>
        {inViewport && (
          <motion.div
            variants={fadeInContainerWithStagger}
            initial="hidden"
            animate="visible"
          >
            <motion.div variants={fadeInUp}>
              <Col>
                <div className={styles.containerFeatures}>
                  <Row>
                    {features.map((feature) => (
                      <Col
                        key={feature.id}
                        sm={6}
                        lg={3}
                        className="d-flex flex-column justify-content-center align-items-center mb-4 md-lg-0"
                      >
                        <img
                          src={feature.icon}
                          alt="img general contractor"
                          className="mb-3"
                        />
                        <h4
                          className={`${styles.containerFeatures__titleFeatureHorizontal} m-0`}
                        >
                          {feature.label}
                          <br />
                          <span style={{ color: colors.secondary }}>
                            {feature.labelFeatured}
                          </span>
                        </h4>
                      </Col>
                    ))}
                  </Row>
                </div>
              </Col>
            </motion.div>
          </motion.div>
        )}
      </Row>
    </Container>
  );
};
