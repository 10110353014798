import clases from './generic.module.css';

interface ICardCategoryProps {
  name: string;
  isSelected: boolean;
  onClick: () => void;
}

export const CardCategory = ({name, isSelected = false, onClick}: ICardCategoryProps) => {
  return (
    <div className={`mb-2 ${clases.cardCategory} ${isSelected ? clases.selected : ''}`} onClick={onClick}>{name}</div>
  )
}
