import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import {
  BannerInfo,
  BigHero,
  ClientSlider,
  FeaturesBanner,
  GallerySlider,
  StatisticsBanner,
  Testimonials,
} from "../components/generic/index";
import { colors } from "../utilities/constants";

export const Home = () => {
  const navigate = useNavigate();

  const handleClickButton = (route: string) => {
    navigate(route);
  };

  const infoConstructionServices = (
    <ul style={{ padding: 0 }}>
      <li style={styles.liBanner}>Guestroom Renovations</li>
      <li style={styles.liBanner}>Soft-Goods/ Hard-Goods</li>
      <li style={styles.liBanner}>Specialty Suites</li>
      <li style={styles.liBanner}>Public Spaces</li>
      <li style={styles.liBanner}>Convention Space</li>
      <li style={styles.liBanner}>Interior Improvements</li>
      <li style={styles.liBanner}>FF&E Warehousing</li>
      <li style={styles.liBanner}>FF&E Installation</li>
      <li style={styles.liBanner}>ADA Upgrades</li>
      <li style={styles.liBanner}>Design/ Build</li>
      <li style={styles.liBanner}>Construction Management</li>
      <li style={styles.liBanner}>General Contracting</li>
      <li style={styles.liBanner}>Pre-Construction Services</li>
      <li style={styles.liBanner}>Ground Up</li>
    </ul>
  );

  const infoProjectBanner = (
    <p>
      At Friedrich Watkins Company, we believe that our work stands as a
      testiment to the quality and excellence that we as a company stand for.
      Here you'll find a selected list of some of our featured work that we
      believe best illustrates the skill, ingenuity and commitment to quality
      that we strive for in every project.
    </p>
  );

  return (
    <>
      <BigHero />
      <FeaturesBanner />
      <section className="my-5">
        <Container>
          <Row>
            <Col
              md={{ span: 8, offset: 2 }}
              lg={{ span: 6, offset: 3 }}
              className="text-center"
            >
              <h2
                style={styles.titleH2}
                className="my-5 h2After marginAfterAuto"
              >
                Building relationships with
                <br />
                dependability
              </h2>
              <p>
                We treat the customer with the respect and dignity of a trusted
                friend. We deliver what is promised.
                <b> We produce the highest quality product possible.</b>
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <BannerInfo
        title="Our construction services"
        info={infoConstructionServices}
        onClick={() => handleClickButton("/projects")}
        label="see our projects"
        background="/assets/images/bg-banner-services.webp"
      />
      <GallerySlider />
      <BannerInfo
        title="Our Projects"
        info={infoProjectBanner}
        onClick={() => handleClickButton("/projects")}
        label="see our projects"
        background="/assets/images/bg-banner-projects.webp"
      />
      <ClientSlider />
      <StatisticsBanner />
      <Testimonials />
    </>
  );
};

const styles = {
  titleH2: {
    color: colors.dark,
  },
  liBanner: {
    display: "inline-block",
    backgroundColor: "rgba(255,255,255,.3)",
    padding: 5,
    marginRight: 5,
    marginBottom: 5,
    borderRadius: 5,
  },
};
