import { Navbar, Container, Nav } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import './Navbar.css';

export const NavbarComponent = () => {
  return (
    <Navbar expand="lg">
      <Container>
        <Link to="/">
          <img src="/assets/images/logo-fwc.webp" alt="Logo FWC" />
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <NavLink to="/" className="nav-item nav-link">Home</NavLink>
            <NavLink to="about-us" className="nav-item nav-link">About</NavLink>
            <NavLink to="projects" className="nav-item nav-link">Projects</NavLink>
            <NavLink to="contact" className="nav-item nav-link">Contact</NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
