import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faLocationDot,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { Col, Container, Row } from "react-bootstrap";
import { HeroPages } from "../components/generic";
import { ContactForm } from "../components/forms/ContactForm";

export const Contact = () => {
  return (
    <>
      <HeroPages
        title="Contact Us"
        background="assets/images/hero-contact.png"
      />
      <section id="contact" className="my-5">
        <Container>
          <Row>
            <Col md={6}>
              <h2>Send us an email</h2>
              <p>
                Our experienced project management team and field staff
                specialize in completing our projects on time, with superior
                quality, always meeting and exceeding the highest of
                expectations. Our goal is to expand our core clientele base into
                the Southeast region of the U.S.
              </p>
              <p>
                We welcome the opportunity to meet with you to discuss resort
                improvement needs.
              </p>
              <ul>
                <li>
                  <FontAwesomeIcon icon={faLocationDot} className="me-2" />
                  ORLANDO
                  <br />
                  3885 Shader Road, Orlando Fl 32808
                </li>
                <li>
                  <FontAwesomeIcon icon={faPhone} className="me-2" />
                  <a href="tel:+14074452000">(407) 445-2000</a>
                </li>
                <li>
                  <FontAwesomeIcon icon={faPrint} className="me-2" />
                  (407) 445-2777
                </li>
                <li>
                  <FontAwesomeIcon icon={faEnvelope} className="me-2" />
                  <a href="mailto:info@fwc.co">info@fwc.co</a>
                </li>
                <li className="mt-3">
                  <FontAwesomeIcon icon={faLocationDot} className="me-2" />
                  TAMPA
                  <br />
                  5521 W Cypress Street, Suite #104, Tampa, FL 33607
                </li>
                <li>
                  <FontAwesomeIcon icon={faPhone} className="me-2" />
                  <a href="tel:+18132812067">(813) 281-2067</a>
                </li>
                <li>
                  <FontAwesomeIcon icon={faPrint} className="me-2" />
                  (813) 281-8086
                </li>
              </ul>
            </Col>
            <Col md={6}>
              <ContactForm />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
