import { colors } from "../../utilities/constants";

interface IFeatureIconProps {
  icon: string;
  title: string;
  description: string;
}

export const FeatureIcon = ({
  icon,
  title,
  description,
}: IFeatureIconProps) => {
  return (
    <>
      <img src={icon} alt={title} />
      <h5 className="my-3" style={styles.title}>
        {title}
      </h5>
      <p style={styles.description}>{description}</p>
    </>
  );
};

const styles = {
  title: {
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "29px",
    color: colors.dark,
  },
  description: {
    fontSize: '15px'
  }
};
