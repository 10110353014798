import { useRef, useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import styles from "./generic.module.css";
import { colors } from "../../utilities/constants";
import { SolidButton, OutlineButton } from "./index";
import { fadeInContainerWithStagger, fadeInUp } from "../../utilities/fadeInUp";

export const BigHero = () => {
  const navigate = useNavigate();

  const handleButton = (route: string) => {
    navigate(route);
  };

  const outerRef = useRef(null);
  const [inViewport, setInViewport] = useState(true);

  useEffect(() => {
    const onChange = (entries: any) => {
      entries.forEach((entry: any) => {
        if (entry.target === outerRef.current) {
          if (entry.isIntersecting) {
            setInViewport(true);
          }
        }
      });
    };
    const observer = new IntersectionObserver(onChange, { threshold: 0.5 });
    observer.observe(outerRef.current!);
  }, [outerRef]);

  return (
    <section className={`${styles.bigHero} d-flex align-items-center h-100`}>
      <Container>
        <Row>
          <Col md={6} ref={outerRef}>
            {inViewport && (
              <motion.div
                variants={fadeInContainerWithStagger}
                initial="hidden"
                animate="visible"
              >
                <motion.div variants={fadeInUp}>
                  <h1
                    className={styles.bigHero__title}
                    style={variables.colorLight}
                  >
                    Building Better Lives
                  </h1>
                </motion.div>
                <motion.div variants={fadeInUp}>
                  <SolidButton
                    text="contact us"
                    color="light"
                    marginEnd={true}
                    onClick={() => handleButton("/contact")}
                  />
                  <OutlineButton
                    text="see our projects"
                    color="light"
                    onClick={() => handleButton("/projects")}
                  />
                </motion.div>
              </motion.div>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

const variables = {
  colorLight: {
    color: `${colors.light}`,
  },
};
