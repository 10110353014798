import { Routes, Route, Navigate } from "react-router-dom";
import { Footer } from "../components/layout/Footer";
import { NavbarComponent } from "../components/layout/Navbar";
import { AboutUs } from "../pages/AboutUs";
import { Contact } from "../pages/Contact";
import { Home } from "../pages/Home";
import { ListProjects } from "../pages/projects/ListProjects";
import { ProjectPage } from "../pages/projects/Project";

export const AppRouter = () => {
  return (
    <>
      <NavbarComponent />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about-us" element={<AboutUs />} />
        <Route path="projects" element={<ListProjects />} />
        <Route path="projects/:id" element={<ProjectPage />} />
        <Route path="contact" element={<Contact />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </>
  );
};
