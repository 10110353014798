import { Row, Col } from "react-bootstrap";
import clases from "./generic.module.css";
import { SolidButton } from "./SolidButton";

interface ICardProjectProps {
  name: string;
  excerpt?: string;
  image: string;
  nameCategory: string | undefined;
  onClick: () => void;
}

const capitalize = (name: string) => {
  const text = name
    .trim()
    .toLowerCase()
    .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
  return text;
};

export const CardProject = ({
  name,
  image,
  excerpt,
  nameCategory = "Category",
  onClick,
}: ICardProjectProps) => {
  return (
    <div className={clases.cardProject}>
      <Row>
        <Col xs={4}>
          <div
            className={clases.cardProjectImage}
            style={{ backgroundImage: `url('${image}')` }}
          ></div>
        </Col>
        <Col xs={8}>
          <div className="d-flex flex-row mb-2">
            <div className={`me-2 ${clases.cardCategoryProject}`}>
              {nameCategory}
            </div>
          </div>
          <h2 className={clases.cardProjectTitle}>{capitalize(name)}</h2>
          <p>{excerpt}</p>
          <SolidButton text="See Project" onClick={onClick} />
        </Col>
      </Row>
    </div>
  );
};
