import { Container, Row, Col } from "react-bootstrap";
import clases from "./generic.module.css";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import './sliders.css';

export const ClientSlider = () => {
  const images = [
    "/assets/images/4R-Circle-Black-Web.webp",
    "/assets/images/blaze-pizza-logo.webp",
    "/assets/images/burton-logo.webp",
    "/assets/images/Disney-Vacation-Club.webp",
    "/assets/images/mesa-21-logo.webp",
    "/assets/images/SeaWorldOrlandoLogo_PNG.webp",
    "/assets/images/tishman_logo.webp",
    "/assets/images/walt-disney-world-logo-vector.webp",
  ];

  const responsive = {
    0: {
      slidesPerView: 1,
      spaceBetween: 0
    },
    480: {
      slidesPerView: 2,
      spaceBetween: 30
    },
    640: {
      slidesPerView: 3,
      spaceBetween: 40
    },
    992: {
      slidesPerView: 4
    },
    1200: {
      slidesPerView: 5,
    }
  }

  return (
    <section className='my-5' id='clients'>
      <Container>
        <Row>
          <Col className='text-center'>
            <h2 className={`${clases.h2After} ${clases.marginAfterAuto} mt-5`}>
              Our Clients
            </h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <Swiper
              grabCursor={true}
              modules={[Pagination]}
              spaceBetween={50}
              slidesPerView={5}
              breakpoints={responsive}
              pagination={{ clickable: true, dynamicBullets: true }}
            >
              {images.map((image) => (
                <SwiperSlide key={image}>
                  <img src={image} alt={image} style={styles.images} />
                </SwiperSlide>
              ))}
            </Swiper>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

const styles = {
  images: {
    width: '150px'
  }
}