import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { CardTeam, HeroPages } from "../components/generic";
import { FeatureIcon } from "../components/generic/FeatureIcon";
import { IconFeature } from "../interfaces/iconFeature.interface";
import { MemberTeam } from "../interfaces/team.interface";
import api from "../services/api";
import { colors } from "../utilities/constants";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const iconFeatures: IconFeature[] = [
  {
    id: "1",
    icon: "assets/images/God.webp",
    title: "GOD",
    description:
      "We believe God is in charge of our lives and this business. We endeavor to live holy and by His command over us in our work.",
  },
  {
    id: "2",
    icon: "assets/images/family.webp",
    title: "FAMILY",
    description:
      "We will balance all aspects of our lives to honor our families as our priority.",
  },
  {
    id: "3",
    icon: "assets/images/chain.webp",
    title: "FREEDOM",
    description:
      "We trust with Integrity and holding to Truth, Family, Relationships and God we will experience Freedom in our lives. Freedom from fear, financial freedom and the freedom to aid those that are oppressed.",
  },
  {
    id: "4",
    icon: "assets/images/handshake.webp",
    title: "RELATIONSHIPS",
    description:
      "We will cultivate relationships through dependable and consistent leadership. Serving our subcontractors by managing our projects professionally and specifically preparing the work so that they may be most effective and profitable.",
  },
  {
    id: "5",
    icon: "assets/images/honest.webp",
    title: "TRUTH",
    description:
      "We will be honest, reveal the facts and deal with them consistently. When we identify that we have made a mistake we will reveal the truth and deal with the consequences.",
  },
];

export const AboutUs = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [team, setTeam] = React.useState<MemberTeam[]>([]);

  const loadTeam = async () => {
    const response = await api.team.getTeamMembers();
    try {
      if (response.ok) {
        const data = await response.json();
        setTeam(data);
      } else {
        MySwal.fire({
          title: "Error!",
          text: "Error get Team in http response. Please reload page.",
          icon: "error",
          confirmButtonText: "Close",
        });
      }
    } catch (error: any) {
      MySwal.fire({
        title: "Error!",
        text: error,
        icon: "error",
        confirmButtonText: "Close",
      });
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    loadTeam();
  }, []);

  return (
    <>
      <HeroPages title="About Us" background="assets/images/hero-about.png" />

      <section className="my-5">
        <Container>
          <Row>
            <Col
              md={{ span: 8, offset: 2 }}
              lg={{ span: 6, offset: 3 }}
              className="text-center"
            >
              <h2
                style={styles.titleH2}
                className="my-5 h2After marginAfterAuto"
              >
                Our strategic plan
              </h2>
            </Col>
          </Row>
          <Row>
            <Col lg={{ span: 6, offset: 3 }}>
              <p className="text-lg-center mb-0">
                <b>
                  Our strategic plan going forward is to continue to fortify our
                  vision by adhering to the FWC Mission Statement and caring for
                  our employees, vendors, and clients.
                </b>{" "}
                We pride ourselves on being one of the only Central Florida
                construction companies that are consistently dependable and
                presenting a high-quality product. We treat every construction
                project as if it is our only one. No matter what the project is,
                FWC provides unrivaled construction services. We minimize
                surprises by consistently monitoring the progress of each
                project and remaining in constant contact with our clients and
                subcontractors.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="mt-5">
        <Container>
          <hr className="my-5" />
          <Row className="align-items-center">
            <Col lg={6}>
              <img
                src="assets/images/fwc-business.webp"
                width="100%"
                alt="about-us"
              />
            </Col>
            <Col lg={6}>
              <h2 className="h2BeforePrimary">Our Mision</h2>
              <h4 style={styles.titleh4} className="mb-3">
                Building success with integrity. Building relationships with
                dependability.
              </h4>
              <p>
                <b>
                  To provide innovative, practical and superior service which
                  meets and exceeds the expectations of our clients.
                </b>{" "}
                To satisfy our clints by demonstrating dependability, and
                unwavering commitment to deliver on our promises. To build long
                term relationships with our clients by building loyalty founded
                on mutual trust and respect.
              </p>
              <p className="mb-0">Building success with integrity.</p>
              <p className="mb-0">Building relationships with dependability.</p>
              <p>
                Provide innovative, practical and top-quality services that
                produce repeat clients because of their satisfaction from our
                dependability, commitment to budget and schedule, and honest
                management. We believe our first responsibility is to our
                clients.
              </p>
              <p>In carrying out our day-to-day business, we strive to:</p>
              <ul>
                <li>
                  Treat our employees with dignity and an eye towards their
                  future with education, fair treatment and rewards.
                </li>
                <li>
                  Care for our customers with the following philosophy:
                  <ul>
                    <li>
                      We treat the customer with respect and dignity of a
                      trusted friend.
                    </li>
                    <li>We deliver what is promised.</li>
                    <li>We produce the highest quality product possible.</li>
                  </ul>
                </li>
                <li>
                  Work with our suppliers and subcontractors in a dignified,
                  fair and businesslike manner, recognizing the contribution
                  they make to our overall goals.
                </li>
                <li>
                  Be civic minded and of sound Christian values in our
                  community.
                </li>
              </ul>
              <p>
                Through a long-term commitment to this mission, we will be known
                as a company that is successful.{" "}
                <b>
                  Our customers, vendors, and employees see The Friedrich
                  Watkins Company as offering benefits, commitment, knowledge,
                  results and friendship.
                </b>
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="mt-5">
        <Container>
          <hr className="my-5" />
          <Row>
            <Col
              md={{ span: 8, offset: 2 }}
              lg={{ span: 6, offset: 3 }}
              className="text-center"
            >
              <h2
                style={styles.titleH2}
                className="mb-5 h2After marginAfterAuto"
              >
                Meet our team
              </h2>
            </Col>
          </Row>
          <Row className="justify-content-center">
            {!isLoading &&
              team.map(({ name, id, role, avatar }: MemberTeam) => (
                <Col md={6} lg={3} key={id}>
                  <CardTeam name={name} role={role} avatar={avatar} id={id} />
                </Col>
              ))}
          </Row>
        </Container>
      </section>

      <section className="mt-5">
        <Container>
          <Row>
            <Col
              md={{ span: 8, offset: 2 }}
              lg={{ span: 6, offset: 3 }}
              className="text-center"
            >
              <h2
                style={styles.titleH2}
                className="mb-5 h2After marginAfterAuto"
              >
                FWC Core Values
              </h2>
            </Col>
          </Row>
          <Row className="justify-content-center">
            {iconFeatures.map(
              ({ title, id, icon, description }: IconFeature) => (
                <Col md={6} lg={4} key={id} className="text-center mb-4">
                  <FeatureIcon
                    title={title}
                    icon={icon}
                    description={description}
                  />
                </Col>
              )
            )}
          </Row>
        </Container>
      </section>
    </>
  );
};

const styles = {
  titleH2: {
    color: colors.dark,
  },
  titleh4: {
    color: colors.primary,
    fontSize: "20px",
    fontWeight: "bold",
  },
};
