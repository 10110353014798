import { useState } from "react";
import { Form, Col, Row } from "react-bootstrap"
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { colors } from "../../utilities/constants"
import { SolidButton } from "../generic";

const MySwal = withReactContent(Swal)

export const ContactForm = () => {

  const [validated, setValidated] = useState(false);
  const [isSending, setIsSending] = useState(false);

  const handleSubmitForm = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setIsSending(true);
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      setIsSending(false)
      return
    }
    emailjs.sendForm('service_4sed36o', 'contact_form_fwc', form, 'G1x83l4xYIn1ZtFC_')
      .then(() => {
          form.reset();
          setValidated(false);
          MySwal.fire({
            title: 'Send Successful',
            text: 'Your message has been sent successfully',
            icon: 'success',
            confirmButtonText: 'Ok'
          })
      }, (error) => {
          console.log(error.text);
          MySwal.fire({
            title: 'Error!',
            text: error.text,
            icon: 'error',
            confirmButtonText: 'Close'
          })
      }).finally(() => setIsSending(false))
  }


  return (
    <Form noValidate validated={validated} onSubmit={handleSubmitForm}>
      <Row>
        <Form.Group as={Col} md="6" className="mb-3" controlId="name">
          <Form.Label>Name <span style={{color: colors.secondary}}>*</span></Form.Label>
          <Form.Control
            type="text"
            placeholder="Ex. John"
            required
            minLength={2}
            maxLength={50}
            name="name" />
          <Form.Control.Feedback type="invalid" style={styles.errorText}>
            The name is required and must be between 2 and 50 characters.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" className="mb-3" controlId="lastName">
          <Form.Label>LastName <span style={{color: colors.secondary}}>*</span></Form.Label>
          <Form.Control
            type="text"
            placeholder="Ex. Doe"
            required
            minLength={2}
            maxLength={50}
            name="lastName" />
          <Form.Control.Feedback type="invalid" style={styles.errorText}>
          The last name is required and must be between 2 and 50 characters.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" className="mb-3" controlId="email">
          <Form.Label>Email <span style={{color: colors.secondary}}>*</span></Form.Label>
          <Form.Control
            type="email"
            placeholder="Ex. johndoe@email.com"
            required
            minLength={5}
            maxLength={100}
            name="email" />
          <Form.Control.Feedback type="invalid" style={styles.errorText}>
          The email is required and must be between 5 and 100 characters.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" className="mb-3" controlId="phone">
          <Form.Label>Phone <span style={{color: colors.secondary}}>*</span></Form.Label>
          <Form.Control
            type="text"
            placeholder="Ex. +152 555 66 77"
            required
            minLength={10}
            name="phone" />
          <Form.Control.Feedback type="invalid" style={styles.errorText}>
          The phone is required and must be at least 10 characters.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} sm={12} className="mb-3" controlId="subject">
          <Form.Label>Subject <span style={{color: colors.secondary}}>*</span></Form.Label>
          <Form.Control
            type="text"
            placeholder="Ex. I need more information"
            required
            minLength={2}
            maxLength={50}
            name="subject" />
          <Form.Control.Feedback type="invalid" style={styles.errorText}>
          The subject is required and must be between 2 and 50 characters.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} sm={12} className="mb-3" controlId="message">
          <Form.Label>Message</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            type="text"
            placeholder="Detail your message here"
            name="message" />
        </Form.Group>
      </Row>      

      <SolidButton text={!isSending ? 'Send Message' : 'Please wait...'} width="block" disabled={isSending} />

    </Form>
  )
}

const styles = {
  errorText: {
    fontSize: '12px',
  }
}
