import { Container, Row, Col } from "react-bootstrap";
import { useCountUp } from "react-countup";
import { colors } from "../../utilities/constants";

export const StatisticsBanner = () => {
  useCountUp({
    ref: "counterClients",
    start: 0,
    end: 50,
    enableScrollSpy: true,
    scrollSpyDelay: 0,
  });
  useCountUp({
    ref: "counterProjects",
    start: 0,
    end: 100,
    enableScrollSpy: true,
    scrollSpyDelay: 0,
  });
  useCountUp({
    ref: "counterDesigns",
    start: 0,
    end: 60,
    enableScrollSpy: true,
    scrollSpyDelay: 0,
  });
  useCountUp({
    ref: "counterRestorations",
    start: 0,
    end: 20,
    enableScrollSpy: true,
    scrollSpyDelay: 0,
  });
  return (
    <section id="statistics" style={styles.statistics}>
      <Container>
        <Row>
          <Col md={6} lg={3} className="text-center">
            <h3 style={styles.numberStatistics} id="counterClients" />
            <h5 style={titleStatistics}>clients</h5>
          </Col>
          <Col md={6} lg={3} className="text-center">
            <h3 style={styles.numberStatistics} id="counterProjects" />
            <h5 style={titleStatistics}>projects</h5>
          </Col>
          <Col md={6} lg={3} className="text-center">
            <h3 style={styles.numberStatistics} id="counterDesigns" />
            <h5 style={titleStatistics}>designs</h5>
          </Col>
          <Col md={6} lg={3} className="text-center">
            <h3 style={styles.numberStatistics} id="counterRestorations" />
            <h5 style={titleStatistics}>restorations</h5>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

const styles = {
  statistics: {
    background: colors.primary,
    color: colors.light,
    padding: "50px 0",
    margin: "100px 0",
  },
  numberStatistics: {
    fontSize: "50px",
    marginBottom: "0",
  },
};
const titleStatistics: React.CSSProperties = {
  fontSize: "15px",
  textTransform: "uppercase",
  fontWeight: "bold",
  marginTop: "0",
};
