

interface IHeroProjectProps {
  image:string
}

export const HeroProject = ({image}: IHeroProjectProps) => {

  const headerPages: React.CSSProperties = {
    backgroundImage: `url(${image})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    minHeight: "300px"
  };

  return (
    <section
      className="position-relative"
      style={headerPages}
    >
      <div className="overlay"></div>
    </section>
  );
};
