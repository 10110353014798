import clases from "./generic.module.css";
import { colors } from "../../utilities/constants";

interface ButtonProps {
  text?: string;
  onClick?: () => void;
  disabled?: boolean;
  width?: "block" | "auto";
  color?: "primary" | "secondary" | "medium" | "dark" | "light";
  marginTop?: boolean;
  marginBottom?: boolean;
  marginStart?: boolean;
  marginEnd?: boolean;
}

export const OutlineButton = ({
  text = "Button",
  disabled = false,
  width = "auto",
  color = "primary",
  marginTop = false,
  marginBottom = false,
  marginStart = false,
  marginEnd = false,
  ...props
}: ButtonProps) => {
  return (
    <>
      <button
        className={`${clases.buttonTheme} ${clases.buttonThemeOutline}`}
        style={{
          ...(width === "block" && styles.block),
          ...(color === "secondary"
            ? styles.secondary
            : color === "medium"
            ? styles.medium
            : color === "dark"
            ? styles.dark
            : color === "light"
            ? styles.light
            : styles.primary),
          ...(marginTop && styles.marginTop),
          ...(marginBottom && styles.marginBottom),
          ...(marginStart && styles.marginStart),
          ...(marginEnd && styles.marginEnd),
        }}
        disabled={disabled}
        {...props}
      >
        {text}
      </button>
    </>
  );
};

const styles = {
  primary: {
    borderColor: `${colors.primary}`,
    color: `${colors.primary}`,
  },
  secondary: {
    borderColor: `${colors.secondary}`,
    color: `${colors.secondary}`,
  },
  medium: {
    borderColor: `${colors.medium}`,
    color: `${colors.medium}`,
  },
  dark: {
    borderColor: `${colors.dark}`,
    color: `${colors.dark}`,
  },
  light: {
    borderColor: `${colors.light}`,
    color: `${colors.light}`,
  },
  block: {
    width: "100%",
  },
  marginTop: {
    marginTop: "10px",
  },
  marginBottom: {
    marginBottom: "10px",
  },
  marginStart: {
    marginLeft: "10px",
  },
  marginEnd: {
    marginRight: "10px",
  },
};
