import { Card } from "react-bootstrap";
import { colors } from "../../utilities/constants";
import clases from "./generic.module.css";

interface ICardTeamProps {
  id: string;
  name: string;
  avatar: string;
  role: string;
}

export const CardTeam = ({ name, role, avatar, id }: ICardTeamProps) => {
  return (
    <Card style={styles.card} className={`p-2 mb-3 ${clases.memberTeam}`}>
      <Card.Img variant="top" src={avatar} />
      <Card.Body>
        <Card.Title style={styles.nameText}>{name}</Card.Title>
        <Card.Text style={styles.roleText}>{role}</Card.Text>
      </Card.Body>
    </Card>
  );
};

const styles = {
  card: {
    width: "100%",
    backgroundColor: colors.primary,
    border: "1px solid #DFDFDF",
    boxShadow: "0px 9px 25px rgba(0, 0, 0, 0.2)",
    borderRadius: "5px",
  },
  nameText: {
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "23px",
    color: colors.light,
  },
  roleText: {
    fontStyle: "italic",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "21px",
    color: colors.light,
  },
};
