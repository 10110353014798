import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faFacebookSquare,
} from "@fortawesome/free-brands-svg-icons";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { colors } from "../../utilities/constants";

export const Footer = () => {
  return (
    <>
      <footer style={footerStyle}>
        <Container>
          <Row>
            <Col md={6}>
              <Link to="/">
                <img src="/assets/images/logo-fwc.webp" alt="Logo FWC" />
              </Link>
              <p className="mb-0 mt-4">
                3885 Shader Road
                <br />
                Orlando, FL 32808, USA
              </p>
            </Col>
            <Col md={6} className="text-md-end d-flex flex-column mt-3 mt-md-0">
              <div className="d-flex justify-content-md-end mb-4">
                <a rel="noreferrer" style={{color: colors.dark, marginRight: '10px'}} href='https://instagram.com/friedrichwatkinsco?igshid=YmMyMTA2M2Y=' target="_blank">
                  <FontAwesomeIcon icon={faInstagram} size='2x' />
                </a>
                <a rel="noreferrer" style={{color: colors.dark}} href='https://www.facebook.com/friedrichwatkinsco/ ' target="_blank">
                  <FontAwesomeIcon icon={faFacebookSquare} size='2x' />
                </a>
              </div>
              <a rel="noreferrer" style={{color: colors.dark, textDecoration: 'none'}} href="tel:4074452000">(407) 445-2000</a>
              <a rel="noreferrer" style={{color: colors.dark, textDecoration: 'none'}} href="mailto:info@fwc.co">info@fwc.co</a>
            </Col>
          </Row>
        </Container>
      </footer>
      <div style={copyright}>
        <p className="m-0">&copy;{`${new Date().getFullYear()}`} FWC - All rights reserved.</p>
      </div>
    </>
  );
};

const footerStyle: React.CSSProperties = {
  backgroundColor: colors.light,
  color: colors.dark,
  padding: "50px 0",
};
const copyright: React.CSSProperties = {
  backgroundColor: colors.dark,
  color: colors.light,
  padding: "10px 0",
  fontSize: '12px',
  textAlign: 'center'
};
