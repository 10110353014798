import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  CardCategory,
  CardProject,
  HeroPages,
  OutlineButton,
} from "../../components/generic";
import { Category } from "../../interfaces/categories.interface";
import { Project } from "../../interfaces/projects.interface";
import api from "../../services/api";

const MySwal = withReactContent(Swal);

export const ListProjects = () => {
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);
  const [isLoadingProjects, setIsLoadingProjects] = useState(false);
  const [categories, setCategories] = useState<Category[]>([]);
  const [categorySelected, setCategorySelected] = useState("");
  const [projects, setProjects] = useState<Project[]>([]);
  const [filteredProjects, setFilteredProjects] = useState<Project[]>([]);

  let navigate = useNavigate();

  const handleClickCategory = (id: string) => {
    setCategorySelected(id);
    const newProjects = projects.filter((project) => project.category === id);
    setFilteredProjects(newProjects);
  };

  const handleClickReset = () => {
    setCategorySelected("");
    setFilteredProjects(projects);
  };

  const handleClickProject = (id: string) => {
    navigate(`/projects/${id}`);
  };

  const loadCategories = async () => {
    setIsLoadingCategories(true);
    const response = await api.categories.getCategories();
    try {
      if (response.ok) {
        const data = await response.json();
        setCategories(data);
      } else {
        MySwal.fire({
          title: "Error!",
          text: "Error get categories in http response. Please reload page.",
          icon: "error",
          confirmButtonText: "Close",
        });
      }
    } catch (error: any) {
      MySwal.fire({
        title: "Error!",
        text: error,
        icon: "error",
        confirmButtonText: "Close",
      });
    } finally {
      setIsLoadingCategories(false);
    }
  };

  const loadProjects = async () => {
    setIsLoadingProjects(true);
    const response = await api.projects.getProjects();
    try {
      if (response.ok) {
        const data = await response.json();
        setProjects(data);
        setFilteredProjects(data);
      } else {
        MySwal.fire({
          title: "Error!",
          text: "Error get projects in http response. Please reload page.",
          icon: "error",
          confirmButtonText: "Close",
        });
      }
    } catch (error: any) {
      MySwal.fire({
        title: "Error!",
        text: error,
        icon: "error",
        confirmButtonText: "Close",
      });
    } finally {
      setIsLoadingProjects(false);
    }
  };

  useEffect(() => {
    loadCategories();
    loadProjects();
  }, []);

  return (
    <>
      <HeroPages
        title="Projects"
        background="assets/images/hero-projects.png"
      />
      <section className="my-5">
        <Container>
          <Row className="align-items-center">
            <Col>
              <h4 className="m-0">Categories</h4>
            </Col>
            <Col className="text-end">
              <OutlineButton text="View All" onClick={handleClickReset} />
            </Col>
          </Row>
          <Row className="mt-4">
            {!isLoadingCategories &&
              categories.length > 0 &&
              categories.map(({ name, id }: Category) => (
                <Col key={id} xs={12} md={6} lg={4}>
                  <CardCategory
                    name={name}
                    isSelected={categorySelected === id ? true : false}
                    onClick={() => handleClickCategory(id)}
                  />
                </Col>
              ))}
          </Row>
        </Container>
      </section>

      <section className="mb-5">
        <Container>
          <Row>
            {!isLoadingProjects &&
              filteredProjects.length > 0 &&
              filteredProjects.map(
                ({ name, id, image, category, excerpt }: Project) => (
                  <Col key={id} xs={12}>
                    <CardProject
                      name={name}
                      image={image}
                      excerpt={excerpt}
                      nameCategory={
                        categories.find((cate) => cate.id === category)?.name
                      }
                      onClick={() => handleClickProject(id)}
                    />
                  </Col>
                )
              )}
          </Row>
        </Container>
      </section>
    </>
  );
};
