import { Container, Row, Col } from "react-bootstrap";
import { SolidButton } from "./SolidButton";
import { colors } from "../../utilities/constants";
import clases from "./generic.module.css";
import { motion } from "framer-motion";
import { useRef, useState, useEffect } from "react";
import { fadeInContainerWithStagger, fadeInUp } from "../../utilities/fadeInUp";

interface IBannerInfoProps {
  title: string;
  info: JSX.Element;
  onClick: () => void;
  label: string;
  background?: string;
}

export const BannerInfo = ({
  title,
  info,
  onClick,
  label,
  background = "/assets/images/bg-banner-projects.webp",
}: IBannerInfoProps) => {
  const styles = {
    backgroundBanner: {
      backgroundImage: `url(${background})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      minHeight: "362px",
      color: colors.light,
    },
  };

  const outerRef = useRef(null);
  const [inViewport, setInViewport] = useState(true);

  useEffect(() => {
    const onChange = (entries: any) => {
      entries.forEach((entry: any) => {
        if (entry.target === outerRef.current) {
          if (entry.isIntersecting) {
            setInViewport(true);
          } else {
            setInViewport(false);
          }
        }
      });
    };
    const observer = new IntersectionObserver(onChange, { threshold: 0.5 });
    observer.observe(outerRef.current!);
  }, [outerRef]);

  return (
    <section
      style={styles.backgroundBanner}
      className="d-flex align-items-center"
      ref={outerRef}
    >
      {inViewport && (
        <motion.div
          variants={fadeInContainerWithStagger}
          initial="hidden"
          animate="visible"
          style={{width: '100%'}}
        >
          <Container>
            <Row>
              <Col md={6}>
                <motion.div variants={fadeInUp}>
                  <h2 className={clases.h2Before}>{title}</h2>
                </motion.div>
              </Col>
              <Col md={6}>
                <motion.div variants={fadeInUp}>
                  {info}
                  <SolidButton text={label} color="light" onClick={onClick} />
                </motion.div>
              </Col>
            </Row>
          </Container>
        </motion.div>
      )}
    </section>
  );
};
